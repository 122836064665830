<template>
  <v-col cols='6' class=''>
    <v-row class='d-flex align-center'>
      <v-col cols='8'>
        <v-text-field
          v-model='formattedDate'
          v-mask:bind='dateMask'
          placeholder='DD/MM/AAAA'
          height='32'
          style='font-size: 14px'
          hide-details
          clearable
          @blur='inputedText'
          @keydown.enter='inputedText'
        />
      </v-col>
      <v-menu
        v-model='showDatePicker'
        :close-on-content-click='false'
        transition='scale-transition'
        offset-y
        max-width='290px'
        min-width='auto'
      >
        <template #activator='{ on, attrs }'>
          <v-icon
            v-bind='attrs'
            class='primary--text mt-4'
            v-on='on'
            @click='showDatePicker = true'
          >
            mdi-calendar-today
          </v-icon>
        </template>
        <v-date-picker v-model='dateFromPicker' color='secondary' no-title @change='setDate' />
      </v-menu>
    </v-row>
  </v-col>
</template>
<script>
  import { mask } from 'vue-the-mask';

  export default {
    directives: {
      mask: mask,
    },
    data: function () {
      return {
        showDatePicker: false,
        dateFromPicker: null,
        date: null,
        formattedDate: null,
        menu: false,
        dateMask: '##/##/####',
      };
    },
    methods: {
      setDate () {
        if (!this.dateFromPicker) {
          this.$emit('change', '');
        }
        this.formattedDate = `${this.dateFromPicker.substring(8, 10)}/${this.dateFromPicker.substring(5, 7)}/${this.dateFromPicker.substring(0, 4)}`;
        this.date = this.dateFromPicker;
        this.showDatePicker = false;
        this.$emit('change', this.date);
      },
      inputedText () {
        if (!this.formattedDate) {
          this.$emit('change', '');
          return;
        }
        if (this.formattedDate !== '' && this.formattedDate.length < 10) {
          return;
        }
        this.date = `${this.formattedDate.substring(6, 10)}-${this.formattedDate.substring(3, 5)}-${this.formattedDate.substring(0, 2)}`;
        this.$emit('change', this.date);
      },
    },
  };
</script>
<style>
</style>
